import React from "react"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import * as sections from "../components/sections"

const AboutPageTemplate = ({ pageContext, data, location }) => {
  const seoData = get(data, 'sanityAboutPage.seo')
  const footerData = get(data, 'sanityFooter')

  const focusKeyword = get(seoData, 'seoTools.focus_keyword')
  const synonyms = get(seoData, 'seoTools.focus_synonyms') || []

  const locale = get(pageContext, 'locale')

  return (
    <>
      <SEO
        title={get(seoData, 'seoTools.seo_title')}
        description={get(seoData, 'seoTools.meta_description')}
        lang={locale}
        pathname={get(location, 'pathname')}
        image={get(seoData, 'seoThumbnail.asset.localFile.childImageSharp.resize')}
        keywords={[focusKeyword, ...synonyms]}
      />
      <Layout locale={get(pageContext, 'locale')} footer footerContent={footerData}>
        <sections.AboutSection locale={locale} />
        <sections.CollaboratorGrid locale={locale} />
      </Layout>
    </>
  )
}

export default AboutPageTemplate

export const query = graphql`
  query AboutPage($slug: String, $locale: String) {
    sanityAboutPage(essentials: {slug: {current: {eq: $slug}}, locale: {eq: $locale}}) {
      seo {
        seoThumbnail {
          asset {
            localFile {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        seoTools {
          focus_keyword
          focus_synonyms
          meta_description
          seo_title
        }
      }
      essentials {
        locale
        slug {
          current
        }
      }
    }
    sanityFooter(locale: {eq: $locale}) {
      copyright
      languageSwitcherLabel
      mission
      links {
        _key
        headline
        links {
          _key
          label
          slug
        }
      }
      newsletter {
        headline
        subline
        buttonText
        emailField {
          type
          placeholder
          label
          identifier
          errors {
            _key
            key
            message
          }
        }
        postSubmitRedirectSlug
        toastError {
          headline
          description
        }
      }
      socialLinks {
        _key
        profileUrl
        type
      }
      logo {
        asset {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`